import { getQueryStringParameter } from 'growth-payments-core/query-string/utils';
import { isComingFromCommerceHubUserGuide } from 'growth-payments-core/urls/utils';
import { DEAL_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useSendCrmMessageTopic } from 'crm-message-bus/useSendCrmMessageTopic';
import { useCallback } from 'react';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { isConvertingFromDealToTool } from './utils/session';
const useHasConvertDealToToolRelatedQueryParams = () => {
  return !!getQueryStringParameter('dealId') && !!getQueryStringParameter('tool');
};

/**
 * @deprecated
 * Use `useConvertDealToToolOnboardingTour` export from the `/hooks` directory instead.
 */
export const useConvertDealToToolOnboardingTour = () => {
  const hasConvertDealToToolRelatedQueryParams = useHasConvertDealToToolRelatedQueryParams();
  const hasConvertDealToToolRelatedSessionParams = isConvertingFromDealToTool();
  const shouldStartConvertDealToToolOnboardingTour = (hasConvertDealToToolRelatedQueryParams || hasConvertDealToToolRelatedSessionParams) && isComingFromCommerceHubUserGuide();
  return shouldStartConvertDealToToolOnboardingTour;
};

/**
 * @deprecated
 * Use `useOpenDealPreviewSidebarOnLoad` export from the `/hooks` directory instead.
 */
export const useOpenDealPreviewSidebarOnLoad = objectTypeId => {
  const hasConvertDealToToolRelatedQueryParams = useHasConvertDealToToolRelatedQueryParams();
  const sendPreviewSidebarMessage = useSendCrmMessageTopic(TOPIC_NAMES.PREVIEW_OBJECT);
  return useCallback(() => {
    if (objectTypeId === DEAL_TYPE_ID && hasConvertDealToToolRelatedQueryParams) {
      const dealId = getQueryStringParameter('dealId');
      sendPreviewSidebarMessage({
        objectId: dealId,
        objectTypeId
      });
    }
  }, [objectTypeId, hasConvertDealToToolRelatedQueryParams, sendPreviewSidebarMessage]);
};