export const CONVERT_DEAL_TO_TOOL_TASK_ID = 'commerce-convert-deal-to-tool';
import { getParentWindow } from 'growth-payments-core/query-string/utils';
export const isConvertingFromDealToTool = () => {
  let onboardingToursGuide;
  try {
    var _getParentWindow;
    onboardingToursGuide = JSON.parse(((_getParentWindow = getParentWindow()) === null || _getParentWindow === void 0 ? void 0 : _getParentWindow.sessionStorage.getItem('ONBOARDING_TOURS_GUIDE')) || '');
  } catch (_unused) {
    return false;
  }
  return onboardingToursGuide.key === CONVERT_DEAL_TO_TOOL_TASK_ID;
};